import Vue from "vue";
// import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import api from "./api";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import Axios from "axios";
import VueMomentLib from "vue-moment-lib";
// import * as Sentry from "@sentry/browser";
// import * as Integrations from "@sentry/integrations";

// Sentry.init({
// 	dsn:
// 		"http://f8b2f339ab2f40cca29269e7f37ef555@ec2-35-183-113-239.ca-central-1.compute.amazonaws.com:9000/3",
// 	integrations: [new Integrations.Vue({ Vue, attachProps: true })],
// });

import "./assets/scss/main.scss";
import "./assets/scss/fonts.scss";

import DefaultLayout from "./layouts/DefaultLayout.vue";
import AuthLayout from "./layouts/AuthLayout.vue";
import i18n from "./plugins/i18n";

Vue.prototype.$api = api;

Vue.component("default-layout", DefaultLayout);
Vue.component("auth-layout", AuthLayout);

Vue.use(VueMomentLib);

Vue.use(Vuelidate);

Vue.prototype.$http = Axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
