import Vue from "vue";
import VueRouter from "vue-router";
import Handbook from "../views/handbooks/Handbook.vue";
import Orders from "../views/orders/Orders.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("@/views/Home.vue"),
		meta: {
			requiresAuth: true,
			title: "Home",
		},
	},
	{
		path: "/orders",
		name: "Orders",
		meta: {
			requiresAuth: true,
		},
		component: Orders,
		children: [
			{
				path: "orderProgress",
				name: "orderProgress",
				component: () => import("../views/orders/OrderProgress.vue"),
				meta: {
					requiresAuth: true,
					// requirePermision: true,
					// permision: store.getters.PERMISION.weighter
				},
			},
			{
				path: "archive",
				name: "archive",
				component: () => import("../views/orders/Arсhive.vue"),
				meta: {
					requiresAuth: true,
					// requirePermision: true,
					// permision: store.getters.PERMISION.weightbox
				},
			},
		],
	},

	{
		path: "/task",
		name: "taskTransport",
		component: () => import("../views/TaskTransport.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/taskEdit/:userId",
		name: "taskEdit",
		component: () => import("../views/TaskEdit.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/users",
		name: "users",
		component: () => import("../views/Users.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/reports",
		name: "reports",
		component: () => import("../views/Reports.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/task/:userId",
		name: "task",
		component: () => import("../views/Task.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/order/:userId",
		name: "order",
		component: () => import("../views/Order.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/orderArhive/:userId",
		name: "orderArhive",
		component: () => import("../views/OrderArhive.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/settings",
		name: "settings",
		component: () => import("../views/Settings.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/handbooks/",
		redirect: { name: "company" },
		meta: {
			requiresAuth: true,
		},
		component: Handbook,
		children: [
			{
				path: "weighter",
				name: "weighter",
				component: () => import("../views/handbooks/Weighter.vue"),
				meta: {
					requiresAuth: true,
					requirePermision: true,
					permision: store.getters.PERMISION.weighter,
				},
			},
			{
				path: "weightbox",
				name: "weightbox",
				component: () => import("../views/handbooks/Weightbox.vue"),
				meta: {
					requiresAuth: true,
					requirePermision: true,
					permision: store.getters.PERMISION.weightbox,
				},
			},
			{
				path: "company",
				name: "company",
				component: () => import("../views/handbooks/Company.vue"),
				meta: {
					requiresAuth: true,
					requirePermision: true,
					permision: store.getters.PERMISION.company,
				},
			},
			{
				path: "transport",
				name: "transport",
				component: () => import("../views/handbooks/Transport.vue"),
				meta: {
					requiresAuth: true,
					requirePermision: true,
					permision: store.getters.PERMISION.transport,
				},
			},

			{
				path: "cargo",
				name: "cargo",
				component: () => import("../views/handbooks/Cargo.vue"),
				meta: {
					requiresAuth: true,
					requirePermision: true,
					permision: store.getters.PERMISION.cargo,
				},
			},
			{
				path: "munit",
				name: "munit",
				component: () => import("../views/handbooks/Munit.vue"),
				meta: {
					requiresAuth: true,
					requirePermision: true,
					permision: store.getters.PERMISION.munit,
				},
			},
		],
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/auth/Login.vue"),
		meta: {
			layout: "auth-layout",
		},
	},
	{
		path: "/registration",
		name: "registration",
		component: () => import("@/views/auth/Registration.vue"),
		meta: {
			layout: "auth-layout",
		},
	},
	{
		path: "*",
		component: () => import("@/views/NotFound"),
		meta: {
			layout: "auth-layout",
			requiresAuth: true,
		},
	},
];
const router = new VueRouter({
	routes,
	mode: "history",
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (store.getters.isLoggedIn) {
			next();
			return;
		}
		next("/login");
	} else {
		next();
	}
});

export default router;
