<template>
  <v-app-bar app clipped-left color="#F1F3F9">
    <v-app-bar-nav-icon @click="menuSwitch()"></v-app-bar-nav-icon>
    <!-- <search /> -->
    <v-spacer />
    <v-avatar size="32" color="transparent">
      <v-icon> mdi-account-circle </v-icon>
    </v-avatar>
    <span>{{ User.fio }}({{ User.role }})</span>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    menuSwitch() {
      let drawer = !this.drawer;
      this.$emit("drawer", drawer);
    },
  },
  computed: {
    ...mapGetters(["User"]),
  },
  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss">
.v-toolbar {
  @media print {
    display: none;
  }
}
</style>
