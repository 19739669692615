<template>
  <v-app>
    <div class="auth-wrapper">
      <router-view />
    </div>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url(../assets/login-bg.jpg);
  background-size: cover;
}
</style>
