import api from "@/api";
export default {
  state: {
    status: "",
    token: localStorage.getItem("token") || null,
    user: JSON.parse(localStorage.getItem("user") || "{}"),
    permision: {
      companyPermissions: {
        directories: ["moderator", "admin"],
        weighter: ["moderator", "admin"],
        weightbox: ["moderator", "admin"],
        company: ["moderator", "admin"],
        transport: ["moderator", "admin"],
        cargo: ["moderator", "admin"],
        munit: ["moderator", "admin"],
        users: ["moderator", "admin"],
        home: ["user", "moderator", "admin"],
        orders: ["user", "moderator", "admin"],
        tasks: ["user", "moderator", "admin"],
        reports: ["user", "moderator", "admin"],
        settings: ["user", "moderator", "admin"]
      },
      userPermissions: {
        users: {
          user: { buyer: false, carrier: false, seller: false },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        weighter: {
          user: { buyer: false, carrier: false, seller: false },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        weightbox: {
          user: { buyer: false, carrier: false, seller: false },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        company: {
          user: { buyer: false, carrier: false, seller: true },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        transport: {
          user: { buyer: false, carrier: false, seller: false },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        cargo: {
          user: { buyer: false, carrier: false, seller: false },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        munit: {
          user: { buyer: false, carrier: false, seller: false },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },

        home: {
          user: { buyer: true, carrier: true, seller: true },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        orders: {
          user: { buyer: true, carrier: false, seller: true },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        tasks: {
          user: { buyer: false, carrier: true, seller: false },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        reports: {
          user: { buyer: false, carrier: true, seller: true },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        },
        settings: {
          user: { buyer: true, carrier: true, seller: true },
          moderator: { buyer: true, carrier: true, seller: true },
          admin: { buyer: true, carrier: true, seller: true }
        }
      }
    }
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token, user }) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    LOGOUT(state) {
      state.status = "";
      state.token = "";
      state.user = {};
    }
  },
  actions: {
    async login({ commit }, payload) {
      let data = await api.login({ data: payload });
      let user = {
        id: data.id,
        company: data.company,
        companyId: data.company_id,
        email: data.email,
        fio: data.fio,
        role: data.role,
        role_buyer: data.role_buyer,
        role_carrier: data.role_carrier,
        role_seller: data.role_seller,
        username: data.username,
        update: data.update_stamp
      };
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(user));
      commit("auth_success", { token: data.token, user: user });
    },

    logout({ commit }) {
      return new Promise(resolve => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        commit("LOGOUT");
        resolve(true);
      });
    }
  },
  getters: {
    getToken: state => state.token,
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    User: state => state.user,
    PERMISION: state => state.permision,
    ROLES: state => {
      return {
        buyer: state.user.role_buyer,
        carrier: state.user.role_carrier,
        seller: state.user.role_seller
      };
    },
    ISUSER: state => state.user.role === "user",
    ISADMINCOMPANY: state => state.user.role === "moderator",
    ISADMIN: state => state.user.role === "admin",
    PermissionsByUserPosition: state => param =>
      state.permision.userPermissions[param][state.user.role]
  }
};
