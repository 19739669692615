<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="closeIcon ? '-1' : '4000'"
    :color="color"
    rounded="pill"
    >{{ GET_MESSAGE }}
    <template v-if="closeIcon" v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Notation",
  computed: {
    ...mapGetters(["GET_TYPE", "GET_VALUE", "GET_MESSAGE"]),
    closeIcon() {
      return this.GET_TYPE.match("error");
    },
    color() {
      if (this.GET_TYPE.match("error")) return "red";
      if (this.GET_TYPE.match("success")) return "green";
      return "orange";
    },
    snackbar: {
      get() {
        return this.GET_VALUE;
      },
      set(newVal) {
        this.VALUE(newVal);
      },
    },
  },
  methods: {
    ...mapActions(["VALUE"]),
  },
};
</script>

<style lang="scss" scoped></style>
