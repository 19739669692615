export default {
  state: {
    lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en-US"
  },
  mutations: {
    SET_LANG_TO_VUEX: (state, payload) => {
      state.lang = payload.lang;
      localStorage.setItem("lang", payload.lang);
    }
  },
  actions: {
    SAVE_LANG({ commit }, payload) {
      commit("SET_LANG_TO_VUEX", payload);
    }
  },
  getters: {
    LANG(state) {
      return { lang: state.lang };
    }
  }
};
