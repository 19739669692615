import api from "@/api";
import { renameKeys } from "@/services";

export default {
  state: {
    tasks: [],
    title: "",
    count: 0,
    taskFields: [],
    task: {},
    tripTask: [],
    tasksOrder: [],
  },

  actions: {
    async getTaskList({ commit, dispatch }, payload) {
      let data = await api[payload.book]({
        args: { args: payload.args },
      });

      if (data.count >= 0) {
        let newFldList = data.results.data.map(function(item) {
          return renameKeys(item, {
            caption: "text",
            field_name: "value",
          });
        });
        commit("SET_TITLE_TO_VUEX", data.results.title);
        commit("SET_COUNT_TO_VUEX", data.count);
        commit("SET_TASKFIELDS_TO_VUEX", newFldList);
      } else {
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: "No data received",
        });
      }
    },
    async getTasksOrder({ commit, dispatch }, payload) {
      let data = await api.tasks({
        args: { id: payload.id },
      });
      if (data.count >= 0) {
        let newFldList = data.results.data.map(function(item) {
          return renameKeys(item, {
            caption: "text",
            field_name: "value",
          });
        });
        commit("SET_TASKSORDER_TO_VUEX", newFldList);
        commit("SET_COUNT_TO_VUEX", data.count);
      } else {
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: "No data received",
        });
      }
    },
    async getTaskByID({ commit, dispatch }, payload) {
      let data = await api.task({ args: { id: payload.id } });

      let newFldList = data.fldlist.map(function(item) {
        return renameKeys(item, {
          caption: "text",
          field_name: "value",
        });
      });
      commit("SET_TASK_TO_VUEX", data.data);
      commit("SET_TASKFIELDS_TO_VUEX", newFldList);
      if (data.data.order) {
        dispatch("getOrderByID", {
          id: data.data.order.id,
        });
      }
    },

    async getTripTask({ commit, dispatch }, payload) {
      let data = await api.getTripTask({ args: { id: payload.id } });
      commit("SET_TRIPTASK_TO_VUEX", data.results.data);
      dispatch("MESSAGE", {
        type: "success",
        value: true,
        message: "Data uploaded",
      });
    },
    async taskItem({ dispatch }, payload) {
      let data = await api[payload.book]({
        args: { id: payload.id },
        data: payload.data,
      });
      if (data.Errors.length <= 0) {
        dispatch("MESSAGE", {
          type: "success",
          value: true,
          message: "Data uploaded",
        });
      } else
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: data.Errors[0],
        });
    },
    async deleteTask({ dispatch }, payload) {
      let data = await api[payload.book]({ args: { id: payload.id } });
      if (data.Errors.length <= 0) {
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: "Data deleted",
        });
      } else
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: data.Errors[0],
        });
    },
  },

  mutations: {
    SET_TASK_TO_VUEX: (state, task) => {
      state.task = task;
    },
    SET_TASKSORDER_TO_VUEX: (state, tasksOrder) => {
      state.tasksOrder = tasksOrder;
    },
    SET_TITLE_TO_VUEX: (state, title) => {
      state.title = title;
    },
    SET_COUNT_TO_VUEX: (state, count) => {
      state.count = count;
    },
    SET_TASKFIELDS_TO_VUEX: (state, taskFields) => {
      state.taskFields = taskFields;
    },
    SET_TRIPTASK_TO_VUEX: (state, tripTask) => {
      state.tripTask = tripTask;
    },
  },

  getters: {
    TASKS(state) {
      return state.tasks;
    },
    TASKSORDER(state) {
      state.tasksOrder.map((el) => {
        const keys = Object.keys(el);
        if (keys.filter((key) => key == "created_dt").length > 0) {
          const date = new Date(el.created_dt);
          el.created_dt = `${date.toLocaleDateString()} / ${date.toLocaleTimeString()}`;
        }
      });
      return state.tasksOrder;
    },
    TASK(state) {
      // let newDate = new Date(state.task.created_dt);
      // state.task.created_dt = `${newDate.toLocaleDateString()} / ${newDate.toLocaleTimeString()}`;
      return state.task;
    },

    TRIPTASK(state) {
      return state.tripTask;
    },

    TASKFIELDS(state) {
      state.taskFields.map((el) => {
        const keys = Object.keys(el);
        if (keys.filter((key) => key == "created_dt").length > 0) {
          const date = new Date(el.created_dt);
          el.created_dt = `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
        }
      });
      return state.taskFields;
    },
    TASKDATA(state, rootState) {
      let arr = [];
      arr.push({ label: "id", value: state.task.id });
      // arr.push({ label: "ssssss", value: state.task.display_message });
      if (state.task.creator) {
        arr.push({ label: "Creator", value: state.task.creator.email });
      }
      if (state.task.order) {
        arr.push({ label: "Order", value: state.task.order.id });
      }
      if (state.task.weightbox) {
        arr.push({
          label: "Weightbox",
          value: state.task.weightbox.name,
        });
      }
      arr.push({
        label: "Actual completion time",
        value: state.task.created_dt,
      });
      if (state.task.driver) {
        arr.push({
          label: "Driver",
          value: state.task.driver.email,
        });
      }
      if (rootState.ORDER && rootState.ORDER.cargo) {
        arr.push({ label: "Cargo", value: rootState.ORDER.cargo.name });
      }
      if (rootState.ORDER && rootState.ORDER.buyer) {
        arr.push({ label: "Buyer", value: rootState.ORDER.buyer.name });
      }
      arr.push({ label: "Status", value: state.task.finished });
      if (state.task.vehicle) {
        arr.push({
          label: "Vehicle",
          value: state.task.vehicle.state_number,
        });
        arr.push({
          label: "Capacity",
          value: state.task.vehicle.capacity_ton,
        });
      }
      return arr;
    },
  },
};
