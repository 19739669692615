<template>
  <v-app>
    <notation />
    <navbar @drawer="menuSwitcher" :drawer="drawer" />
    <sidebar @drawer="menuSwitcher" :drawer="drawer" />
    <v-main app class="main-content">
      <v-container fluid>
        <div class="main-content">
          <router-view />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import notation from "@/components/Notation.vue";
import navbar from "@/components/Navbar.vue";
import sidebar from "@/components/Sidebar.vue";
export default {
  components: {
    navbar,
    sidebar,
    notation,
  },

  data: () => ({
    drawer: true,
  }),
  methods: {
    menuSwitcher(drawer) {
      this.drawer = drawer;
    },
  },

  name: "DefaultLayout",
};
</script>

<style lang="scss">
.click {
  position: absolute;
  left: 59%;
  top: 20%;
  z-index: 1000;
}
.main-content {
  padding: 20px;
}
</style>
