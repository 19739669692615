import Vue from "vue";
import Vuex from "vuex";

import layout from "./modules/layout";
import general from "./modules/general";
import auth from "./modules/auth";
import notation from "./modules/notation";
import settings from "./modules/settings";
import progress from "./modules/progress";
import tasks from "./modules/tasks";
import order from "./modules/order";
import books from "./modules/books";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		layout,
		general,
		auth,
		notation,
		settings,
		progress,
		tasks,
		order,
		books
	},
});
