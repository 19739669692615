<template>
  <v-navigation-drawer
    app
    clipped
    color="#4E5471"
    dark
    permanent
    width="275"
    :mini-variant="drawer"
    mini-variant-width="62"
  >
    <!-- @click="$emit('drawer', true)" -->
    <v-list class="menu__list" dense>
      <div class="menu__item" v-for="(item, i) in menuList" :key="i">
        <v-list-item
          v-if="item.submenu.length === 0 && isPermision(item.permision)"
          active-class="is_active"
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title>{{ $t(item.translation) }}</v-list-item-title>
        </v-list-item>

        <v-list-group
          color="#fff"
          :value="isOpen"
          :prepend-icon="item.icon"
          v-else-if="item.submenu.length > 0 && isPermision(item.permision)"
          @click="$emit('drawer', false)"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ $t(item.translation) }}</v-list-item-title>
          </template>

          <div v-for="(subitem, i) in item.submenu" :key="i" class="bg">
            <v-list-item
              link
              :to="subitem.to"
              v-if="isPermision(subitem.permision)"
            >
              <v-list-item-icon>
                <v-icon>remove</v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                {{ $t(subitem.translation) }}
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list-group>
      </div>

      <div class="menu__item">
        <v-list-item @click="onLogout()" active-class="is-active">
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="isLoggedIn">{{
            $t("menu.logout")
          }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    handbooks: [],
    group: null
  }),
  computed: {
    isOpen() {
      return this.drawer ? null : false;
    },
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    permisionId: function() {
      return this.$store.getters.User.role;
    },
    roles: function() {
      return this.$store.getters.ROLES;
    },

    ...mapGetters(["PERMISION", "PermissionsByUserPosition", "ISADMIN"]),
    menuList: function() {
      let menu = [
        {
          title: "Home",
          to: "/",
          icon: "home",
          translation: "menu.home",
          submenu: [],
          permision: {
            companyPermissions: this.$store.getters.PERMISION.companyPermissions
              .home,
            userPermissions: this.$store.getters.PermissionsByUserPosition(
              "home"
            )
          }
        },
        {
          title: "Orders",
          to: "",
          icon: "pending_actions",
          translation: "menu.orders",
          submenu: [
            {
              translation: "orders.inProgress",
              to: "/orders/orderProgress",
              permision: {
                companyPermissions: this.$store.getters.PERMISION
                  .companyPermissions.orders,
                userPermissions: this.$store.getters.PermissionsByUserPosition(
                  "orders"
                )
              }
            },
            {
              translation: "orders.archive",
              to: "/orders/archive",
              permision: {
                companyPermissions: this.$store.getters.PERMISION
                  .companyPermissions.orders,
                userPermissions: this.$store.getters.PermissionsByUserPosition(
                  "orders"
                )
              }
            }
          ],
          permision: {
            companyPermissions: this.$store.getters.PERMISION.companyPermissions
              .orders,
            userPermissions: this.$store.getters.PermissionsByUserPosition(
              "orders"
            )
          }
        },
        {
          title: "Tasks",
          to: "/task",
          icon: "local_shipping",
          translation: "menu.tasks",
          submenu: [],
          permision: {
            companyPermissions: this.$store.getters.PERMISION.companyPermissions
              .tasks,
            userPermissions: this.$store.getters.PermissionsByUserPosition(
              "tasks"
            )
          }
        },
        {
          title: "Users",
          to: "/users",
          icon: "person_outline",
          translation: "menu.users",
          submenu: [],
          // permision: [1],
          permision: {
            companyPermissions: this.$store.getters.PERMISION.companyPermissions
              .users,
            userPermissions: this.$store.getters.PermissionsByUserPosition(
              "users"
            )
          }
        },
        {
          title: "Directories",
          to: "",
          icon: "menu_book",
          translation: "menu.guides",
          permision: {
            companyPermissions: this.$store.getters.PERMISION.companyPermissions
              .users,
            userPermissions: this.$store.getters.PermissionsByUserPosition(
              "users"
            )
          },
          submenu: [
            {
              translation: "handbooks.scales",
              to: "/handbooks/weighter",
              // permision: [{ user: 3, role: 2 }],
              permision: {
                companyPermissions: this.$store.getters.PERMISION
                  .companyPermissions.weighter,
                userPermissions: this.$store.getters.PermissionsByUserPosition(
                  "weighter"
                )
              }
            },
            {
              translation: "handbooks.weighing",
              to: "/handbooks/weightbox",
              // permision: [3],
              permision: {
                companyPermissions: this.$store.getters.PERMISION
                  .companyPermissions.weightbox,
                userPermissions: this.$store.getters.PermissionsByUserPosition(
                  "weightbox"
                )
              }
            },
            {
              translation: "handbooks.company",
              to: "/handbooks/company",
              // permision: [1, 2, 3],
              permision: {
                companyPermissions: this.$store.getters.PERMISION
                  .companyPermissions.company,
                userPermissions: this.$store.getters.PermissionsByUserPosition(
                  "company"
                )
              }
            },

            {
              translation: "handbooks.transport",
              to: "/handbooks/transport",
              // permision: [2, 3],
              permision: {
                companyPermissions: this.$store.getters.PERMISION
                  .companyPermissions.transport,
                userPermissions: this.$store.getters.PermissionsByUserPosition(
                  "transport"
                )
              }
            },
            {
              translation: "handbooks.goods",
              to: "/handbooks/cargo",
              // permision: [2, 3],
              permision: {
                companyPermissions: this.$store.getters.PERMISION
                  .companyPermissions.cargo,
                userPermissions: this.$store.getters.PermissionsByUserPosition(
                  "cargo"
                )
              }
            },
            {
              translation: "handbooks.unit",
              to: "/handbooks/munit",
              // permision: [2, 3],
              permision: {
                companyPermissions: this.$store.getters.PERMISION
                  .companyPermissions.munit,
                userPermissions: this.$store.getters.PermissionsByUserPosition(
                  "munit"
                )
              }
            }
          ]
        },
        {
          title: "Reports",
          to: "/reports",
          icon: "error_outline",
          translation: "menu.reports",
          submenu: [],
          permision: {
            companyPermissions: this.$store.getters.PERMISION.companyPermissions
              .reports,
            userPermissions: this.$store.getters.PermissionsByUserPosition(
              "reports"
            )
          }
        },

        {
          title: "Settings",
          to: "/settings",
          icon: "settings",
          translation: "menu.settings",
          submenu: [],
          permision: {
            companyPermissions: this.$store.getters.PERMISION.companyPermissions
              .settings,
            userPermissions: this.$store.getters.PermissionsByUserPosition(
              "settings"
            )
          }
        }
      ];
      return menu;
    }
  },
  methods: {
    ...mapActions(["logout"]),
    onLogout() {
      this.logout().then(result => {
        if (result) this.$router.push("/login");
      });
    },
    isPermision(element) {
      let userPermision;
      if (
        this.ISADMIN ||
        (this.roles.buyer && this.roles.carrier && this.roles.seller) ||
        (this.roles.buyer && element.userPermissions.buyer) ||
        (this.roles.seller && element.userPermissions.seller) ||
        (this.roles.carrier && element.userPermissions.carrier)
      ) {
        userPermision = true;
      } else {
        userPermision = false;
      }
      return element.companyPermissions.some(function(permision) {
        return permision === this.permisionId && userPermision;
      }, this);
    }
  },
  created: function() {
    this.$http.interceptors.response.use(undefined, function(err) {
      return new Promise(function(resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        resolve();
        reject(err);
      });
    });
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    group() {
      this.drawer = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.list-height {
  height: 100%;
}
.bg {
  background-color: #454b66;
  border-bottom: 1px solid #4e5471;
  position: relative;

  &:first-child {
    &::after {
      content: "";
      position: absolute;
      right: 20px;
      top: 0;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #4e5471;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}
.menu__big {
  &:nth-last-child(2) {
    margin-top: auto;
  }
}
.is_active {
  background: #71778a;
}
.menu__list {
  height: 100%;
  position: relative;
}
.menu__item:last-child {
  position: absolute;
  width: 100%;
  bottom: 0;
  margin-top: auto;
}
.menu__item:nth-last-child(-2n + 2) {
  position: absolute;
  width: 100%;
  bottom: 40px;
  margin-top: auto;
}
</style>
