function renameKeys(item) {
  if (item) {
    let newVal = { ...item };
    Object.keys(item).map(el => {
      if (
        el !== "type" &&
        el !== "blank" &&
        el !== "can edit" &&
        el !== "choices"
      ) {
        newVal.text = item[el];
        newVal.value = el;
        newVal.visible = true;
      }
      if (el === "type") {
        newVal.type = item.type;
      }
    });

    return { ...newVal };
  }
  return {};
}
export { renameKeys };
