export default {
  state: {
    type: "",
    value: false,
    message: ""
  },
  actions: {
    VALUE({ commit }, payload) {
      commit("SET_VALUE", payload);
    },
    MESSAGE({ commit }, payload) {
      commit("SET_TYPE", payload.type);
      commit("SET_VALUE", payload.value);
      commit("SET_MESSAGE", payload.message);
    }
  },
  mutations: {
    SET_TYPE: (state, payload) => {
      state.type = payload;
    },
    SET_VALUE: (state, payload) => {
      state.value = payload;
    },
    SET_MESSAGE: (state, payload) => {
      state.message = payload;
    }
  },
  getters: {
    GET_TYPE(state) {
      return state.type;
    },
    GET_VALUE(state) {
      return state.value;
    },
    GET_MESSAGE(state) {
      return state.message;
    }
  }
};
