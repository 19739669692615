import api from "@/api";
import { renameKeys } from "@/services";
export default {
  state: {
    bookList: [],
    title: "",
    bookFields: [],
    count: 0,
    addingFields: []
  },

  mutations: {
    SET_BOOKLIST_TO_VUEX: (state, payload) => {
      state.bookList = payload;
    },
    SET_TITLE_TO_VUEX: (state, title) => {
      state.title = title;
    },
    SET_BOOKFIELDS_TO_VUEX: (state, payload) => {
      state.bookFields = payload;
    },
    SET_COUNT_TO_VUEX: (state, count) => {
      state.count = count;
    },
    SET_ADDING_FIELDS(state, payload) {
      state.addingFields = payload;
    }
  },

  actions: {
    async getFields({ commit }, payload) {
      let data = await api[`get${payload.book}Fields`]();
      let newFldList = data.results.fldlist.map(function(item) {
        return renameKeys(item, {
          caption: "text",
          field_name: "value"
        });
      });
      commit("SET_ADDING_FIELDS", newFldList);
    },

    async getItemByID({ dispatch }, payload) {
      return new Promise(resolve => {
        api[payload.book]({ args: { id: payload.id } }).then(resp => {
          if (resp.data) {
            dispatch("MESSAGE", {
              type: "success",
              value: false,
              message: "Data added"
            });

            let headers = resp.fldlist.map(function(item) {
              return renameKeys(item, {
                caption: "text",
                field_name: "value"
              });
            });
            resolve({ item: resp.data, headers: headers });
          }
        });
      });
    },

    async deleteBookItem({ dispatch }, payload) {
      let data = await api[payload.book]({ args: { id: payload.id } });

      if (data.Errors.length <= 0) {
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: "Data deleted"
        });
      } else
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: data.Errors[0]
        });
    },

    async createBookItem({ dispatch }, payload) {
      let data = await api[payload.book]({ data: payload.data });

      if (data.Errors.length <= 0) {
        dispatch("MESSAGE", {
          type: "success",
          value: true,
          message: "Data added"
        });
      } else
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: data.Errors[0]
        });
    },

    async editBookItem({ dispatch }, payload) {
      let { id, data } = payload;
      let copyObject = JSON.stringify(data);
      let result = JSON.parse(copyObject);
      for (let el in result) {
        if (el === "creator" || el === "driver") {
          for (let key in result[el]) {
            if (key === "name") {
              result[el]["email"] = result[el][key];
              delete result[el][key];
            }
          }
        } else if (el === "vehicle") {
          for (let key in result[el]) {
            if (key === "name") {
              result[el]["state_number"] = result[el][key];
              delete result[el][key];
            }
          }
        }
      }
      let resp = await api[payload.book]({
        args: { id: id },
        data: result
      });
      if (resp.Errors.length <= 0) {
        dispatch("MESSAGE", {
          type: "success",
          value: true,
          message: "Data changed"
        });
      } else
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: resp.Errors[0]
        });
    },

    async getBookList({ commit, dispatch }, payload) {
      let data = await api[payload.book]({
        args: { args: payload.args }
      });

      if (data.count >= 0) {
        let newFldList = data.results.fldlist.map(function(item) {
          return renameKeys(item, {
            caption: "text",
            field_name: "value"
          });
        });
        newFldList.push({
          text: "actions",
          sortable: false,
          value: "actions",
          visible: true
        });

        commit("SET_BOOKLIST_TO_VUEX", data.results.data);
        commit("SET_TITLE_TO_VUEX", data.results.title);
        commit("SET_COUNT_TO_VUEX", data.count);
        commit("SET_BOOKFIELDS_TO_VUEX", newFldList);
      } else {
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: "No data received"
        });
      }
    }
  },

  getters: {
    BOOKLIST(state) {
      state.bookList.map(el => {
        const keys = Object.keys(el);
        if (keys.filter(key => key == "created_dt").length > 0) {
          const date = new Date(el.created_dt);
          el.created_dt = `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
        }
        if (keys.filter(key => key == "update_stamp").length > 0) {
          const date = new Date(el.update_stamp);
          el.update_stamp = `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
        }
      });
      return state.bookList;
    },
    BOOKFIELDS(state) {
      return state.bookFields;
    },
    TITLE(state) {
      return state.title;
    },
    COUNT(state) {
      return state.count;
    },
    ADDINGFIELDS(state) {
      return state.addingFields;
    }
  }
};
