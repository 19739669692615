/* eslint-disable no-prototype-builtins */
import api from "@/api";
import { renameKeys } from "@/services";

export default {
  state: {
    order: {},
    orderList: [],
    orderFields: []
  },
  actions: {
    async getOrderList({ commit, dispatch }, payload) {
      let data = await api.order({
        args: { args: payload.args }
      });

      if (data.count >= 0) {
        let newFldList = data.results.fldlist.map(function(item) {
          return renameKeys(item, {
            caption: "text",
            field_name: "value"
          });
        });
        newFldList.push({
          text: "actions",
          sortable: false,
          value: "actions",
          visible: true
        });
        commit("SET_DATA_TO_VUEX", {
          target: "orderList",
          data: data.results.data
        });
        commit("SET_TITLE_TO_VUEX", data.results.title);
        commit("SET_COUNT_TO_VUEX", data.count);
        commit("SET_DATA_TO_VUEX", {
          target: "orderFields",
          data: newFldList
        });
      } else {
        dispatch("MESSAGE", {
          type: "orange",
          value: true,
          message: "No data received"
        });
      }
    },
    async getOrderByID({ commit }, payload) {
      let data = await api.order({ args: { id: payload.id } });
      if (data.fldlist?.length >= 0) {
        let newFldList = data.fldlist.map(function(item) {
          return renameKeys(item);
        });
        commit("SET_DATA_TO_VUEX", {
          target: "order",
          data: data.data
        });
        commit("SET_DATA_TO_VUEX", {
          target: "orderFields",
          data: newFldList
        });
      }
    }
  },
  mutations: {
    SET_DATA_TO_VUEX: (state, payload) => {
      state[payload.target] = payload.data;
    }
  },

  getters: {
    ORDER(state) {
      if (
        state.order.hasOwnProperty("notation") &&
        state.order.notation === null
      ) {
        state.order.notation = "";
      }
      return state.order;
    },
    ORDERLIST(state) {
      return state.orderList;
    },
    ORDERFIELDS(state) {
      return state.orderFields;
    }
  }
};
