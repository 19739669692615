export const BACKEND_ENDPOINTS = {
  login: {
    method: "POST",
    url: "login/"
  },

  //task(задача)
  taskDrivers: {
    method: "GET",
    url: "drivers/task/"
  },
  task: {
    method: "GET",
    url: "task/"
  },
  tasks: {
    method: "GET",
    url: "tasks/"
  },
  taskCreate: {
    method: "POST",
    url: "task/"
  },
  taskEdit: {
    method: "PATCH",
    url: "task/"
  },
  taskDelete: {
    method: "DELETE",
    url: "task/"
  },
  getTaskFields: {
    method: "GET",
    url: "fields/task/"
  },
  getTripTask: {
    method: "GET",
    url: "trip/"
  },

  //oreder(заказ)
  order: {
    method: "GET",
    url: "order/"
  },
  orderCreate: {
    method: "POST",
    url: "order/"
  },
  orderEdit: {
    method: "PATCH",
    url: "order/"
  },
  orderDelete: {
    method: "DELETE",
    url: "order/"
  },
  getOrderFields: {
    method: "GET",
    url: "fields/order/"
  },

  //users
  profile: {
    method: "GET",
    url: "users/"
  },
  profileCreate: {
    method: "POST",
    url: "users/"
  },
  profileEdit: {
    method: "PATCH",
    url: "users/"
  },
  profileDelete: {
    method: "DELETE",
    url: "users/"
  },
  getUserFields: {
    method: "GET",
    url: "fields/users/"
  },

  //company
  company: {
    method: "GET",
    url: "company/"
  },
  companyCreate: {
    method: "POST",
    url: "company/"
  },
  companyEdit: {
    method: "PATCH",
    url: "company/"
  },
  companyDelete: {
    method: "DELETE",
    url: "company/"
  },
  getlists: {
    method: "GET",
    url: "lists/"
  },
  getCompanyFields: {
    method: "GET",
    url: "fields/company/"
  },

  //cargo(груз)
  cargo: {
    method: "GET",
    url: "cargo/"
  },
  cargoCreate: {
    method: "POST",
    url: "cargo/"
  },
  cargoEdit: {
    method: "PATCH",
    url: "cargo/"
  },
  cargoDelete: {
    method: "DELETE",
    url: "cargo/"
  },
  getCargoFields: {
    method: "GET",
    url: "fields/cargo/"
  },

  //measure(мера)
  measure: {
    method: "GET",
    url: "measure/"
  },
  measureCreate: {
    method: "POST",
    url: "measure/"
  },
  measureEdit: {
    method: "PATCH",
    url: "measure/"
  },
  measureDelete: {
    method: "DELETE",
    url: "measure/"
  },
  getMeasureFields: {
    method: "GET",
    url: "fields/measure/"
  },

  //vehiclekind(вид транспорта)
  vehiclekind: {
    method: "GET",
    url: "vehiclekind/"
  },
  vehiclekindCreate: {
    method: "POST",
    url: "vehiclekind/"
  },
  vehiclekindEdit: {
    method: "PATCH",
    url: "vehiclekind/"
  },
  vehiclekindDelete: {
    method: "DELETE",
    url: "vehiclekind/"
  },
  getVehiclekindFields: {
    method: "GET",
    url: "fields/vehiclekind/"
  },

  //weightbox(весовая)
  weightbox: {
    method: "GET",
    url: "weightbox/"
  },
  weightboxCreate: {
    method: "POST",
    url: "weightbox/"
  },
  weightboxEdit: {
    method: "PATCH",
    url: "weightbox/"
  },
  weightboxDelete: {
    method: "DELETE",
    url: "weightbox/"
  },
  getWeightboxFields: {
    method: "GET",
    url: "fields/weightbox/"
  },

  //weighter(весы)
  weighter: {
    method: "GET",
    url: "weighter/"
  },
  weighterCreate: {
    method: "POST",
    url: "weighter/"
  },
  weighterEdit: {
    method: "PATCH",
    url: "weighter/"
  },
  weighterDelete: {
    method: "DELETE",
    url: "weighter/"
  },
  getWeighterFields: {
    method: "GET",
    url: "fields/weighter/"
  },

  //vehicle(транспорт)
  vehicle: {
    method: "GET",
    url: "vehicle/"
  },
  vehicleCreate: {
    method: "POST",
    url: "vehicle/"
  },
  vehicleEdit: {
    method: "PATCH",
    url: "vehicle/"
  },
  vehicleDelete: {
    method: "DELETE",
    url: "vehicle/"
  },
  getVehicleFields: {
    method: "GET",
    url: "fields/vehicle/"
  }
};
