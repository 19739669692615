<template>
<div id="app">
    <component :is="layout">
        <transition name="moveInUp">
            <router-view />
        </transition>
    </component>
</div>
</template>

<script>
export default {
    computed: {
        layout() {
            return this.$route.meta.layout || "default-layout";
        },
    },
    created() {
        localStorage.getItem("lang") ? "" : localStorage.setItem("lang", "en-US");
        this.$root.$i18n.locale = localStorage.getItem("lang" || "en-US");
    },
};
</script>

<style lang="scss" scoped>
.moveInUp-enter-active {
    animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
</style>
